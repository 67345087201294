import React from 'react'
import Main from '../../components/main/Main'
import MyHelmet from '../../components/Helmets/MyHelmet'
import Partner from '../../components/partners/Partner'
import "./Home.css"
import rice from "../../images/rice.jpg";
import maize from "../../images/maize.jpg";
import chicken from "../../images/manychicks_bgwhite.jpg";
import soyabean from "../../images/soybeans.jpg";
import About from '../about/About'
import Contact from '../contact/Contact'
import { Link } from 'react-router-dom'
import ChatBox from '../chats/ChatBox'
import WhatsApp from '../../components/twalks/WhatsApp'


const desc = "Farms in Nigeria, Farms in Abuja, Best Farm, chicken farms, chicken meat, "

const Home = () => {
  return (
    <div  style={{width:"100%", position:"relative"}}>
      <MyHelmet title={`Hellinda Farms & Allied`} url={`/`} desc={desc}/>
        <Main/>
         <div className='crops'>
          <a href='crops'>
          <div class="headers">
        <div class="titles">
        <h2 class="title-text">How Your Purchase Helps the Planet.</h2>
        </div>
       <div class="row-lines">
            <span class="first-line"></span>
            <span class="middle-line"></span>
            <span class="last-line"></span>
       </div>
    </div>
          </a>
          <div className="content-row">
        
        <div className="content-column">
            <div className="crop-image">
            <img src={chicken} alt="" />
            </div>
            <h3 className='crop-title'>
                Chicken Farm
            </h3>
            <p className="description">
            Each order supports: Our family of farmers, who preserve land through sustainable farming practice
            </p>
            <Link to="/chickens" className="crop-link">Read More</Link>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={soyabean} alt="" />
            </div>
            <h3 className='crop-title'>
                Soyabeans Farm
            </h3>
            <p className="description">
            Each order supports: Our family of farmers, who preserve land through sustainable farming practice
            </p>
            <Link to="/crops" className="crop-link">Read More</Link>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={rice} alt=""/>
            </div>
            <h3 className='crop-title'>
            Rice Farm
            </h3>
            <p className="description">
                Description
            </p>
            <Link to="/crops" className="crop-link">Read More</Link>
        </div>
        <div className="content-column">
            <div className="crop-image">
            <img src={maize} alt=""/>
            </div>
            <h3 className='crop-title'>
                Maize Farm
            </h3>
            <p className="description">
                Description
            </p>
            <Link to="/crops" className="crop-link">Read More</Link>
        </div>

    </div>
        </div>
        {/* <h2 className='center-content'>About Hellinda Farms </h2>  */}
        <About/>
        <Partner/>
        <Contact/>
       
        {/* <ChatBox style={{display:"block", position:"absolute",bottom:"50px", left:"5px"}}/> */}
    </div>
  )
}

export default Home