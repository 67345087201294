import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import { About, Career, Carts, Sidebar, Checkorder, Checkout, Contact, Crops, Footer, ForgotPassword, Home, Investment, Investors, Login, Maincontent, Nextofkin, Orders, Payment, Privacy, ResetPassword, Riders, Signup, UserDetails, Chickens, Training, TrainingCreate, TrainingList } from "./pages";
import React, { createContext, useEffect, useState } from "react";
import ShopContextProvider from "./components/context/ShopContext";
import Shop from "./components/shop/Shop";
import Dashboard from "./pages/users/Dashboard";
import "./externalcss.css";
import Flutterwaves from "./components/Payment-gate-ways/Fluttervaves";
import InvestorPayment from "./pages/users/investor-payment";


export const AppContext = createContext();

function App() {
const location = useLocation();
  const [isLoggedIn, setIsaloggedIn] = useState(false);
  useEffect(()=>{
    let loggedinuser = JSON.parse(localStorage.getItem("logedinuser"));
    if(loggedinuser){
      setIsaloggedIn(true)
    }
  },[])

  return (
    <div className="App">
       
    <ShopContextProvider className="home-container">
     {location.pathname === "/trainings"?"": <Navbar/>}
    
        <div className="content-wrapper">
        {/* {isLoggedIn && <Sidebar/>} */}
        <Routes>
          <Route path="/">
            <Route index element={<Home/>} />
            <Route path="about" element={<About/>} />
            <Route path="investor-payment/:id" element={<InvestorPayment/>} />
            <Route path="flutter-waves" element={<Flutterwaves/>} />
            <Route path="contact" element={<Contact/>} />
            <Route path="signup" element={<Signup/>} />
            <Route path="login" element={<Login/>} />
            <Route path="trainings" element={<Training/>} />
            <Route path="training-registration-account" element={<TrainingCreate/>} />
            <Route path="trainings-list" element={<TrainingList/>} />
            <Route path="investors" element={<Investors/>} />
            <Route path="careers" element={<Career/>} />
            <Route path="userdetails" element={<UserDetails/>} />
            <Route path="nextofkin" element={<Nextofkin/>} />
            <Route path="recoverpassword" element={<ForgotPassword/>} />
            <Route path="resetpassword" element={<ResetPassword/>} />
            <Route path="riders" element={<Riders/>} />
            <Route path="userdashboard" element={<Dashboard/>} />
            <Route path="shopping" element={<Shop/>} />
            <Route path="carts" element={<Carts/>} />
            <Route path="main-content" element={<Maincontent/>} />
            <Route path="checkout" element={<Checkout/>} />
            <Route path="orders" element={<Orders/>} />
            <Route path="payment" element={<Payment/>} />
            <Route path="investment" element={<Investment/>} />
            <Route path="crops" element={<Crops/>} />
            <Route path="chickens" element={<Chickens/>} />
            <Route path="privacy-policy" element={<Privacy/>} />
            <Route path="*" element={<p>Page Not Found</p>} />
          </Route>
        </Routes>
       
        </div>
        </ShopContextProvider>
        {location.pathname === "/trainings"?"":<Footer/>}
    </div>
  );
}

export default App;
