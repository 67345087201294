import React, { useContext, useState } from 'react'
import "./footer.scss"
import bankcard from "../../images/bank-card.webp"
import { useNavigate } from 'react-router-dom';
import api from '../../components/api/api';
import SweetAlert2 from 'react-sweetalert2';
import WhatsApp from '../twalks/WhatsApp';

const Footer = () => {

  // const {resetLoader, loader} = useContext(ShopContext);
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const onChangeValue = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]:e.target.value
    });
  } 

  const sendMessage = (e) => {
    e.preventDefault();
    // resetLoader(true);
    api.post("/contacts", userInfo).then(({data})=>{
      if(data.message === "Success"){
        setSwalProps({
          show: true,
          title: 'Success',
          text: 'Message sent successfully',
      });
    
        navigate("/");
      }
      console.log(data.message)
      // resetLoader(false);
     
    }).catch(error=>{
      console.log(error)
      setSwalProps({
        show: true,
        title: 'Error',
        text: 'Internal server error',
    });
      // resetLoader(false);
    })
  }
  return (
    <div className='footer-container' style={{position:"relative"}}>
       <WhatsApp/>
    <div className="footer-row">
      <div className="footer-column">
        <h3>Customer Service</h3>
        <a href='/login'>MY ACCOUNT</a>
      </div>
      <div className="footer-column">
        <h3>Company</h3>
        <a href='/about'>OUR STORY</a>
      </div>
      <div className="footer-column">
        <h3>International </h3>
        <a href='/login'>RETAIL TRADE</a>
      </div>
      <div className="footer-column">
      <a href="/privacy-policy"><h3>Privacy </h3></a>
      <a href='/login'>WEBSITE TERMS OF USE</a>
      </div>
      <div className="footer-column">
        <h4>
        Be the first to know about exclusive offers, new products, recipes and more!
        </h4>
        <input type="text" placeholder='Enter your email address' name='email' onChange={onChangeValue}/>
        <button type="submit" onClick={sendMessage} >
                {/* {loader ?  <span><span className="loader"></span> <span>Processing</span></span> */}
              Send message <i class="fa fa-paper-plane-o" style={{fontSize:"18px",color:"white"}}></i>
              </button>
      </div>
    </div>
    <div className="row">
      <img src={bankcard} alt="" />
    </div>
    {/* <hr style={{width:'100%',margin:'20px 0'}}/>
    
    <div className="row" style={{alignItems:"center", justifyContent:"space-between", width:"90%"}}>
     <p class="PP">
        <a className="whatsapp"
          href="https://wa.me/+2348087970846"
          target="_blank"
          rel="noopener noreferrer"
          style={{color:"#5F6368", textDecoration:"none"}}
        >
         <i class="fa fa-whatsapp" style={{fontSize:"24px", background:"#3399ff"}}></i>
        </a>
      </p>
      <a style={{color:"#FFF", textDecoration:"none"}}
              target="_blank"
              href="https://web.facebook.com/Edutrique/"
            >
          <div class="SocialMedia" style={{background:"#3348AB"}}>
        
          </div>
          </a>
          <a style={{color:"#FFF", textDecoration:"none"}}
              target="_blank"
              href="https://twitter.com/edutrique"
            ><div class="SocialMedia" style={{background:"#3399ff"}}>
              <i class="fa fa-twitter-square" style={{fontSize:"48px",color:"red"}}></i>
              </div>
          </a>
          <a style={{color:"#FFF", textDecoration:"none"}}
              target="_blank"
              href="https://www.instagram.com/edutrique2/?hl=en"
            ><div class="SocialMedia" style={{background:"#ff33d6"}}>
             
              </div>
          </a>
          <a style={{color:"#FFF", textDecoration:"none"}}
              target="_blank"
              href="https://www.linkedin.com/company/edutrique/"
            ><div class="SocialMedia" style={{background:"#49728c"}}> 
           
            </div>
          </a>
     </div> */}

    <hr style={{width:'100%',margin:'20px 0'}}/>
     <div class="Row">
     <p>All right reserved @ by Hellinda Farms and Allied </p>
     <p>Designed, Host and Manage by TOPCHOICE TECHNOLOGIES COMPANY (+ 2348153033861 )</p>
        </div>
        <SweetAlert2 {...swalProps} />
    </div>
  )
}

export default Footer