import React from 'react'
import "./whatsapp.css"

const WhatsApp = () => {
  return (
    <div className='whatsapp-message'>
      <a 
          href="https://wa.me/+2348087970846"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration:"none"}}
        >
         <i className="fa fa-whatsapp" style={{fontSize:"24px", color:"#FFF"}}></i>
        </a>
    </div>
  )
}

export default WhatsApp
