import React from 'react'
import { Link } from 'react-router-dom'
import MyHelmet from '../../components/Helmets/MyHelmet'
import "./trainning.scss"
import finger from "../../images/finger.png"
import dayOld from "../../images/day-old.jpg"
import oneWeek from "../../images/one-week.jpg"
import processing from "../../images/processing.jpg"
import packaging from "../../images/packaging.jpg"
const index = () =>
{
  return (

    <div className='major training-bg' >

      <div className='contact-container' style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>

        <MyHelmet title={`Hellinda Farms Poultry Training`} />
        <div className="contact-us-container">
          <div style={{ paddingBottom: "20px" }}>
            <p style={{ textAlign: "center" }}>
              <a href="https://chat.whatsapp.com/CG4C1U8FCYD10Tu8ZwAEXL"
                style={{
                  background: 'green',
                  color: "white",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: 'pointer',
                  width: "100px",
                  marginBottom: '10px'
                }}
              >Click here to join </a>
            </p>
          </div>
          <div className='columns'>

            <h3 style={{ color: "green" }}> HELLINDA FARMS AND ALLIED.</h3>
            <h3 style={{ color: "red" }}> ACQUIRE A SKILL IN ANIMAL FARMING.</h3>
            <p className='paragraph' style={{ color: "green", fontWeight: "bold", fontSize: "20px" }}>Are you ready?</p>
          </div>
          <div className="columns">

            <p className='paragraph'> Learn how to Produce your own meat today and stop spending huge to provide protein at home.</p>
            <p className='paragraph'>We are HELLINDA FARMS. We run a free training program once in a year for aspiring animal farmers. Those that wish to go into any kind of Animal farming like poultry, cattle, sheep and goat farming, pig farming, fish farming. And meat processing. We are here for you. And for those that already into raising animal that are not getting it right, this is the opportunity for you to get to the right track.

            </p>
            <img src={oneWeek} height={300} />
            <p className='paragraph' style={{ marginTop: "20px" }}>
              We do this as a way of giving back to the society. Every household need this training. Imagine producing your household meat that will be consumed at your home at a little cost compare to buying meat every time. That is even though you don’t want to do it as a business. Kindly join this class to learn today.
              {/* Registration for the free training is <span style={{fontWeight:"bold", color:'red'}}>1,000</span> naira only.  */}
            </p>
            <p className='paragraph' style={{ display: "flex" }}>
              <img src={finger} width={50} />
              <a href="https://chat.whatsapp.com/CG4C1U8FCYD10Tu8ZwAEXL"
                style={{
                  color: "green", fontWeight: "bold", fontSize: "20px", padding: "10px",
                  border: '1px solid red', borderRadius: "5px"

                }}> Click here to join </a>
            </p>
            <p className='paragraph'>
              HELLINDA FARM is a global farm ecosystem located at Abuja Nigeria, we focus production on broilers. We raise broilers from day old to table meat size at 6 weeks.
            </p>
            <img src={dayOld} height={300} />
            <p className='paragraph' style={{ marginTop: "20px" }}>
              Our meat processing center is located at No 6, Gwandu Close, Directly Opposite Bolton White Hotels, Close to Sahad Stores, Garki Area 11, Abuja (FCT) Nigeria</p>
            <p className='paragraph'>
              We process our chicken and package them in our brand name in different parts and sell out as frozen chicken.
            </p>
            <p className='paragraph'>Whether you are a farmer or seeking for knowledge to start your poultry farming you are in the right place this training is for you.</p>
            <div className='processing'>
              <img src={processing} width={450} />
              <img src={packaging} width={450} />
            </div>
            <p className='paragraph'>
              We are dedicated to provide you with a practical knowledge and insight to help you succeed in the industry.
            </p>
            <p className='paragraph'>We will teach you important things you need to know about establishing and managing a successful business farm.</p>
            <ul>
              <li className="item">
                we will teach you day to day routine management practice

              </li>
              <li className="item">Common dieses identification and treatment for farm animals </li>
              <li className="item">Feasibility studies</li>
              <li className="item">How to cut down cost of production in this current economy</li>
              <li className="item">Post mortem examination</li>
              <li className="item">Bio security</li>
              <li className="item">Processing of your animal </li>
              <li className="item">Marketing (how you can sell whatever you produce)</li>
            </ul>
            <p className='paragraph'>
              we will also offer networking opportunities that enable you to connect with poultry farmers and stakeholders, industry experts and suppliers within our community space across Nigeria. We help trainees to create partnerships and stay updated on the latest trends and development in the Nigerian poultry industry.
            </p>
            <p className='paragraph' style={{ color: "green", fontWeight: "bold", marginBottom: "20px" }}>
              Don’t miss this opportunity today.
            </p>
            <p style={{ textAlign: "center" }}>
              <a href="https://chat.whatsapp.com/CG4C1U8FCYD10Tu8ZwAEXL"
                style={{
                  background: 'green',
                  color: "white",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: 'pointer',
                  width: "100px",
                }}
              >Click here to join </a>
            </p>
          </div>
        </div>
      </div>


    </div>
  )
}

export default index
