import React, { useContext, useEffect, useState } from 'react'
import "./navbar.scss"
import "./mobile.scss"
import { Link } from 'react-router-dom'
import MyLogo  from './../../images/logo1.png';
import Chickenx from '../chichens/Chickenx';
import { ShopContext } from '../context/ShopContext';
import admin from "../../images/administrator-male.png"

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [menu, setMenu] = useState(false);
  const [visility, setVisibility] = useState(true);
  const [chickens, setChickens] = useState(true);
  const {logout, qty} = useContext(ShopContext);
  // const [showNav, setShowNav] = useState(true)

  const [isLoggedIn, setIsaloggedIn] = useState(false);
  useEffect(()=>{
    let loggedinuser = JSON.parse(localStorage.getItem("logedinuser"));
    if(loggedinuser){
      setIsaloggedIn(true)
    }
  },[])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(parseInt(window.scrollY) > 70);
    });
    
  }, []);
  return (
    <div style={{width:"100%"}}>
    <div className="top">
        <div className="top-items" style={{position:'relative', zIndex:'1'}}>
            <span ><i className="fa fa-map-marker" aria-hidden="true"></i> Abuja (FCT) | </span>
            <span > &nbsp;<i className="fa-solid fa-phone fa"> </i> 08087970846 | </span>
            <span> &nbsp;<i className="fa-solid fa-envelope fa"></i> info@hellindafarms.com </span>
            <Link onClick={()=>setMenu(!menu)} to={""}>Careers</Link>
            <Link onClick={()=>setMenu(!menu)} to={"/shopping"}>Where to Buy</Link>
            <Link onClick={()=>setMenu(!menu)} to={"/contact"}>Support</Link>

            {!isLoggedIn && <Link to={"/login"} ><i className="fa-solid fa-lock fa"></i> Login</Link>}
            <Link onClick={()=>setMenu(!menu)} to={"/investment"} className="become-investor" > Become Investor</Link>
            <span onMouseEnter={()=>setVisibility(!visility)} className='mycarts' onClick={()=>window.location = "carts"}>
              <i className="fa fa-shopping-cart " ></i>&nbsp;
            Cart 
            <span className="cartcount">
              {qty}
            </span>
            </span>
        </div>
       {/* <Carts visibility={visility} setVisibility={setVisibility} className="cards"/> */}
    </div>
    <nav className={scroll ? "navbar add-shadow" : "navbar"}>
          <Link onClick={()=>setMenu(!menu)} to="/">
            <img src={MyLogo} className='logo' alt=''/>
          </Link> 
          <span onMouseEnter={()=>setVisibility(!visility)} className='mycarts mobile' onClick={()=>window.location = "carts"}>
              <i className="fa fa-shopping-cart " ></i>&nbsp;
            <span className="cartcount">
              {qty}
            </span>
          
            </span>
            <Link  to="/trainings" className='mycarts mobile'
             style={{
              background:'green',
              color:"white",
              fontWeight:"bold",
              padding:"7px 20px",
              borderRadius:"5px",
              cursor:'pointer',
              width:"100px",
            }}
            >Training</Link> 
        <ul className={menu ? "menu active":"menu"}>
            <li><Link onClick={()=>setMenu(!menu)} to="/chickens" className='item main-items'>Chicken <i className="fa fa-caret-down" aria-hidden="true"></i></Link> </li>
            <li><Link onClick={()=>setMenu(!menu)} to="/crops" className='item main-items'>Crops <i className="fa fa-caret-down" aria-hidden="true"></i></Link> </li>
            <li><Link onClick={()=>setMenu(!menu)} to="/about" className='item main-items'>About Us</Link> </li>
            <li><Link onClick={()=>setMenu(!menu)} to="/contact" className='item main-items'>Contact Us</Link> </li>
            <li><Link onClick={()=>setMenu(!menu)} to="/contact" className='item main-items'>Consultant</Link> </li>
            <li><Link onClick={()=>setMenu(!menu)} to="/login" className='item main-items mobile'>Login</Link> </li>
            
            <li><Link onClick={()=>setMenu(!menu)} to="/trainings" className='item main-items'
            style={{
              background:'green',
              color:"white",
              fontWeight:"bold",
              padding:"7px 20px",
              borderRadius:"5px",
              cursor:'pointer',
              width:"100px",
            }}
            >Training</Link> </li>
            <li><Link onClick={()=>setMenu(!menu)} to={"/investment"} className="become-investor signup mobile"
            style={{border:"1px solid #FFF;"}}
            > Become Investor</Link></li>
            <li> 
             {isLoggedIn ? 
                  <ul>
                    <li className='admin-user'>
                      <img src={admin} alt='Login user' style={{height:"50px"}} />
                      <ul className='profile-menu'>
                          <li>
                            <Link onClick={()=>setMenu(!menu)} to={`/userdashboard`} className='item'>Profile</Link>
                          </li>
                          <li>
                            <span onClick={logout} className='item'>Logout</span>
                          </li>
                      </ul>
                    </li>
                  </ul>
              :<Link onClick={()=>setMenu(!menu)} to={`/signup`} className='signup'> Sign Up</Link>}
            
            </li>
        </ul>
        <span onClick={()=>setMenu(!menu)} className='hambuger' >
          {!menu ? <i className="fa fa-bars my-fa-bars" aria-hidden="true"></i> :
          <i class="fa fa-times my-fa-bars" aria-hidden="true"></i>}
        </span>
    </nav>
    {/* <Chickenx chickens={chickens} setChickens={setChickens}/> */}
    </div>
    
  )
}

export default Navbar