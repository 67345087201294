import React, { useContext, useEffect, useState } from 'react'
import api from '../../components/api/api';
import "./dashboard.css"
import { ShopContext } from '../../components/context/ShopContext';

const Dashboard = () => {
    const [user, setUser] = useState({});
    const [amount, setAmount] = useState(0);
    const [investment, setInvestment] = useState([]);
    const [repayment, setRepayment] = useState([]);
    const [interesttenure, setIterestTenure] = useState("");
    const {loader, resetLoader} = useContext(ShopContext);
    const [interest, setInterest] = useState(0)
    let i = 0;
    useEffect(()=>{
      let user = JSON.parse(localStorage.getItem("logedinuser"));
        api.get(`/dashboard/${user.id}` ).then(({data})=>{
            setUser(data.user)
            setInvestment(data.investment);
            setRepayment(data.repayment);
        }).catch((e)=>{
            console.log(e)
        })
    }, [])

    const saveData = (e) => {
      e.preventDefault();
      resetLoader(true);
      let user = JSON.parse(localStorage.getItem("logedinuser"));
      if(interesttenure === 1 || interesttenure ===""){
        alert("Please select interest tenure")
      }else if(amount < 100000){
        alert("Amount cannot be less than One Hundred Thousand Naira (100, 000)")
      }else{
      api.post("/investment", {amount, user_id:user.id, rate:30, interesttenure}).then(({data})=>{
        if(data.message === "Success"){
          localStorage.setItem("investment", JSON.stringify(data.investment))
          window.location = `/investor-payment/${data.investment.txt_reference}`;
        }
        resetLoader(false);
      }).catch(error => {
        resetLoader(false);
        console.log(error)
        if(error.response.status==409){
          alert("duplicate transaction wait for some time!")
        }
      })
    }
    }

    let total_investment = 0
    let total = 0;
    for(let i = 0; i<repayment.length; i++){
      total = total+parseFloat(repayment[i].amount)
    }
    for(let i = 0; i<investment.length; i++){
      total_investment = total_investment+parseFloat(investment[i].amount)
    }
   

  return (
    <div className='dashboard-container'>
        <div className="home-link">
          <span>Home / Dashboard </span>
        </div>
        <hr />
        <div className="container-shadow">
          {/* <div className="container-row">
          <span style={{color:"red"}}>No investment record found</span>
          <span> New investment </span>
          </div> */}
          <div className="container-row">

          <div className='form-group interest-control pad' style={{background:"blue", color:"white"}}>
            <label htmlFor="">Amount: </label>
            <input type='number' step={100000} placeholder='Enter your investment amount' 
            onChange={(e)=>{
              setAmount(e.target.value)
              setInterest(e.target.value * 0.3)
            }} className='form-control interest-control' min={100000}/>
          </div> 
          <div className='form-group interest-control pad' style={{border:"1px solid grey", borderRadius:"5px",padding:"10px"}}>
            <p>Rate of Interest (30% per annum) </p>
            {interest>0&&<p style={{color:"green", margin:"10px", fontWeight:"bold"}}>Interest: 
              {interest.toLocaleString()} </p>}
            </div>
            <div className='form-group interest-control pad'>
          <p className='pad'>Interest Payment: (How do we pay you?) </p>
            <select onChange={(e)=>setIterestTenure(e.target.value)} 
            className='form-control interest-control'> 
              <option value={1}>- Select interest payment plan -</option>
              <option value={'Annual'}>Annually</option>
              <option value={'Quarterly'}>Quarterly</option>
              <option value={'Monthly'}>Monthly</option>
            </select>
          </div>
          <button className='proceed-btn' onClick={saveData} >Proceed</button>
          </div>
        </div>
        {investment.length > 0 ? 
        <div className='principal-container'>
          <span className='details'>Investment Details: Total Investment ({parseInt(total_investment).toLocaleString()})</span>
          <table className='table table-responsive'>
            <tr>
              <th>Principal Amount: </th>
              <th>Date: </th>
              <th>Status: </th>
            </tr>
          
          {investment.map((item)=>{
            return(
              <tr key={item.id}>
                <td>{parseInt(item.amount).toLocaleString()}</td>
                <td>{item.created_at.substr(0,10)}</td>
                <td>{item.status}</td>
              </tr>
            )
          })

          }
          </table>
          <span className='details'>Interest/Return on Investment Details: {parseInt(total).toLocaleString()}</span>
          <hr style={{width:"100%"}}/>
          <table className='table table-responsive'>
            <tr>
              <th>SN</th>
              <th>Interest</th>
              <th>Month</th>
              <th>Status</th>
            </tr>
          
          {repayment.map((item)=>{
             i++;
            return(
              <tr key={item.id}>
                <td>{i}</td>
                <td>{parseFloat(item.amount).toLocaleString()}</td>
                <td>{item.month}</td>
                <td>{item.status}</td>
              </tr>
            )
          })

          }
          </table>
        </div>
        :""}
    </div>
  )
}

export default Dashboard