import React, { useContext, useState } from 'react'
import "./signup.scss"
import admin from "../../images/administrator-male.png"
import { useNavigate } from 'react-router-dom';
import api from '../../components/api/api';
import Swal from 'sweetalert2'
import { ShopContext } from '../../components/context/ShopContext';
import pay from "../../images/paystck.png"
import { PaystackButton } from 'react-paystack';
import logo from "../../images/logo1.png"

let config = {
  reference: (new Date()).getTime().toString(),
  email: '',
  amount: 100000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
  // publicKey: 'pk_test_5a51f78313c6d5cea55e77dafb8adbb0dba0cb82',
  publicKey: 'pk_live_eeae4531150612d41299b54cbb6ffa80bfe286ef',
};


const Training = () => {
    const {loader, resetLoader} = useContext(ShopContext);
    const [showPassword, setShowPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({
      email: '',
      surname: '',
      firstname: '',
      othernames: '',
      phone: '',
      password: '',
    });
    const onChangeValue = (e) => {
      setUserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
    } 

    const saveData = (e) => {
      e.preventDefault();
      resetLoader(true);
      api.post("/trainings", userInfo).then(({data})=>{
        localStorage.setItem("logedinuser", JSON.stringify(data.user))
        config.email= data.user.email
          Swal.fire({
            icon:"success",
            text:data.message
          })
          setSuccess(true)
      
        resetLoader(false);
      }).catch(error => {
        setSuccess(false)
        resetLoader(false);
        Swal.fire({
          text:error.response.data.message,
          icon:"error"
        })
      })
    }

    const PaymentUpdate = (id)=>{
      api.post(`training-payment/${id}`).then(({data})=>{
        window.location = 'https://chat.whatsapp.com/CG4C1U8FCYD10Tu8ZwAEXL'
      }).catch((err)=>{
        alert("Payment not successful")
        //  window.location = '/trainings'
      });
    }

    const handlePaystackSuccessAction = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      // reference.user_id = user.id;
      reference.amount  = config.amount;
      reference.channel = "Paystack";
      let user = JSON.parse(localStorage.getItem("logedinuser"));
      PaymentUpdate(user.id);
    };
  
    // you can call this function anything
    const handlePaystackCloseAction = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      // window.location = '/'
      // window.location = "/userdashboard"
    }
  
    const componentProps = {
        ...config,
        text: `Pay Now ${config.amount/100} NGN`,
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: handlePaystackCloseAction,
    };
  
  return (
    <div className='form-container'>
    <div className="center-content">
     
    <div>
    {!success ? <form>
      <h3 style={{color:"green"}}>CREATE YOUR TRAINING ACCOUNT WITH HELLINDA FARMS & ALLIED (GROW WITH US)</h3>
      <div className='form-group'>
        <input type="text" name="email" id="" className='form-control' placeholder='Email address'
        onChange={onChangeValue}
        />
      </div>
     
     
      <div className='form-group'>
        <input type="text" name="surname" id="" className='form-control'
          onChange={onChangeValue} placeholder='Surname'
        />
      </div>
      <div className='form-group'>
        <input type="text" name="firstname" id="" className='form-control'
          onChange={onChangeValue} placeholder='First name'
        />
      </div>
      <div className='form-group'>
        <input type="text" name="othernames" id="" className='form-control'
          onChange={onChangeValue} placeholder='Othername'
        />
      </div>
      <div className='form-group'>
        <input type="text" name="phone" id="" className='form-control'
          onChange={onChangeValue} placeholder='Phone number'
        />
      </div>
     
      <div className='' style={{width:'94%', marginLeft:'3px'}}>
        <div className="password-wrapper">
          <input 
          type={
            showPassword ? "text" : "password"
          }
          onChange={onChangeValue} placeholder='Create password'
          name="password" 
          id="" className='form-control'/>
          <span>
            {showPassword ? <i class="fa fa-eye" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i> 
            :  <i class="fa fa-eye-slash" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i>
            }
          </span>
        </div>
      </div>
      <div className='form-group'>
        <button type="submit" onClick={saveData} className='button-control' style={{background:'#0085BC',opacity:'.8'}}>
       {loader ?  <span><span className="loader"></span> <span>Processing please wait</span></span>
       :" Create Account"
       
      }
        </button>
      </div>
    </form> : 
    <div>
      <p>Your registration has been successfully received by Hellinda Farms and Allied. </p>
      <div className='invoicecontainer'>
     
            <img src={logo} alt='Hellinda Logo' style={{width:"100px"}}/>
      <hr />

      <div>
      <PaystackButton {...componentProps} className='pay-now' />
       
      </div>
      <img src={pay} alt="" width={350}/>
    </div>
      </div>}
    </div>
    </div>
  </div>
  )
}

export default Training